<template>
  <ul id="tree" class="tree list-unstyled mt-3">
    <div v-for="classification of classifications" :key="classification.id">
      <TreeItem
        class="item"
        :item="classification"
        :active="setActiveData"
        @add-item="addItem"
      />
    </div>
    <Modal
      :title="$t('profile_classification_modal_name_add')"
      :id-modal="'addItem'"
      size="md"
    >
      <template #modalBody>
        <AddItemForm :confirmation="addItem" :activeItem="activeData" />
      </template>
    </Modal>
    <Modal
      :title="
        `${$t('profile_classification_modal_name_delete')} ${
          activeData.laName
        } ?`
      "
      :id-modal="'deleteItem'"
      size="md"
      :confirmation="() => requestDeleteItem(activeData.id)"
    />
    <Modal
      :title="$t('profile_classification_modal_name_edit')"
      :id-modal="'editItem'"
      size="md"
    >
      <template #modalBody>
        <AddItemForm
          :confirmation="editItem"
          :activeItem="activeData"
          :isEdit="true"
        />
      </template>
    </Modal>
  </ul>
</template>

<script>
import TreeItem from './TreeItem'
import Modal from '@/components/Modal'
import AddItemForm from './AddItemForm'

export default {
  name: 'Tree',
  components: { TreeItem, Modal, AddItemForm },
  props: {
    classifications: { type: Array, default: () => {} }
  },
  data() {
    return {
      activeData: {}
    }
  },
  methods: {
    addItem(item, data) {
      let arr = [...(item.children || [data])]

      if (item.children) {
        arr.push(data)
      }

      this.$set(item, 'children', arr)
    },
    editItem(item, data) {
      let arr = []
      let index = null

      if (data.type === 'Genus') {
        index = this.classification.children.findIndex(
          el => el.id === data.parentId
        )
        arr = this.classification.children[index].children
        index = arr.findIndex(el => el.id === data.id)
      } else {
        index = this.classification.children.findIndex(el => el.id === data.id)
        arr = this.classification.children
      }
      this.$set(arr, index, { ...data, children: item.children || null })
    },
    setActiveData(item) {
      this.activeData = item
    },
    deleteItem(id, data = this.classification) {
      Object.keys(data).forEach(el => {
        if (Array.isArray(data[el])) {
          const checkId = data[el].some(el => el.id === id)
          if (checkId) {
            data[el] = data[el].filter(el => el.id !== id)
            return
          } else {
            data[el].forEach(el => this.deleteItem(id, el))
          }
        }
      })
    },
    async requestDeleteItem(id) {
      const code = await this.$store.dispatch(
        'classification/deleteClassification',
        id
      )
      if (code === 'success') {
        this.deleteItem(id)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tree {
  text-align: left;
}
</style>
