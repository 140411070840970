<template>
  <div class="classification p-3">
    <h1>{{ $t('profile_classification_title') }}</h1>
    <Alphabet />
    <div v-if="loading" class="d-flex justify-content-center my-3">
      <b-spinner style="width: 3rem; height: 3rem;" variant="success" />
    </div>
    <Tree v-if="isRender" :classifications="classifications" />
    <p v-if="!loading && !isRender" class="text-center mt-3">
      {{ $t('profile_classification_choose') }}
    </p>
  </div>
</template>

<script>
import Tree from './Classification/Tree'
import Alphabet from './Classification/Alphabet'
import { mapGetters } from 'vuex'

export default {
  components: { Tree, Alphabet },
  computed: {
    ...mapGetters('classification', ['family', 'loading']),
    isRender() {
      return Object.keys(this.family).length ? true : false
    },
    classifications() {
      return this.family
    }
  }
}
</script>

<style lang="scss" scoped>
.classification {
  background: #ffffff;
}
</style>
