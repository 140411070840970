<template>
  <li class="p-2">
    <div :class="{ bold: isFolder }">
      {{
        `${
          item.type
            ? $t('profile_classification_item_type_' + item.type) + ':'
            : ''
        } ${
          item.laName === 'Classification'
            ? $t('profile_classification_item_type_Classification')
            : item.laName
        }`
      }}
      <b-button
        v-if="isFolder"
        variant="light"
        class="ml-2"
        size="sm"
        @click="toggle"
      >
        <b-icon v-if="isOpen" icon="arrow-up" class="primary" />
        <b-icon v-else icon="arrow-down" />
      </b-button>
      <b-button
        v-if="item.type !== 'Genus'"
        variant="light"
        size="sm"
        class="add ml-2"
        @click="addItem()"
      >
        <b-icon icon="plus" />
      </b-button>
      <b-button
        v-if="item.type !== ''"
        variant="light"
        size="sm"
        class="ml-2"
        @click="removeItem()"
      >
        <b-icon icon="dash" />
      </b-button>
      <b-button
        v-if="item.type !== ''"
        variant="light"
        size="sm"
        class="add ml-2"
        @click="editItem()"
      >
        <b-icon icon="pencil" />
      </b-button>
    </div>
    <ul v-show="isOpen" v-if="isFolder" class="ml-2 list-unstyled">
      <TreeItem
        v-for="(child, index) in item.children"
        :key="index"
        class="item"
        :item="child"
        :active="active"
        @add-item="$emit('add-item', $event)"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TreeItem',
  props: {
    item: { type: Object, default: () => {} },
    active: { type: Function },
    deleteItem: { type: Function }
  },
  data: function() {
    return {
      isOpen: false
    }
  },
  computed: {
    isFolder: function() {
      return this.item.children && this.item.children.length
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        this.isOpen = !this.isOpen
      }
    },
    removeItem() {
      this.active(this.item)
      this.$root.$emit('bv::show::modal', 'deleteItem')
    },
    addItem() {
      this.active(this.item)
      this.$root.$emit('bv::show::modal', 'addItem')
    },
    editItem() {
      this.active(this.item)
      this.$root.$emit('bv::show::modal', 'editItem')
    }
  }
}
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
