<template>
  <b-form @submit.prevent="submitHandler">
    <h5 v-if="!isEdit">
      {{
        $t('profile_classification_modal_title_add') +
          ' ' +
          (activeItem.type
            ? $t('profile_classification_item_type_' + activeItem.type) + ' => '
            : '') +
          (activeItem.laName === 'Classification'
            ? $t('profile_classification_item_type_' + activeItem.laName)
            : activeItem.laName)
      }}
    </h5>
    <h5 v-else>
      {{
        $t('profile_classification_modal_title_edit') +
          ' ' +
          $t('profile_classification_item_type_' + activeItem.type) +
          ' : ' +
          activeItem.laName
      }}
    </h5>
    <b-form-group
      :label="$t('profile_classification_latin name')"
      label-for="laName"
      label-cols="4"
    >
      <b-form-input
        id="laName"
        v-model.trim="$v.form.laName.$model"
        :state="validateState('laName')"
      />
      <b-form-text
        v-if="$v.form.laName.$dirty && !$v.form.laName.required"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_empty') }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.laName.$dirty && !$v.form.laName.maxLength"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_length') }}
        {{ $v.form.laName.$params.maxLength.max }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.laName.$dirty && !$v.form.laName.alphaLA"
        text-variant="danger"
      >
        {{
          $t('profile_classification_modal_la_input_valid_message') +
            ` ${symbol}`
        }}
      </b-form-text>
    </b-form-group>
    <b-form-group
      :label="$t('profile_classification_english name')"
      label-for="enName"
      label-cols="4"
    >
      <b-form-input
        id="enName"
        v-model.trim="$v.form.enName.$model"
        :state="validateState('enName')"
      />
      <b-form-text
        v-if="$v.form.enName.$dirty && !$v.form.enName.required"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_empty') }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.enName.$dirty && !$v.form.enName.maxLength"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_length') }}
        {{ $v.form.enName.$params.maxLength.max }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.enName.$dirty && !$v.form.enName.alphaEN"
        text-variant="danger"
      >
        {{ $t('profile_classification_modal_en_input_valid_message') }}
      </b-form-text>
    </b-form-group>
    <b-form-group
      :label="$t('profile_classification_russian name')"
      label-for="ruName"
      label-cols="4"
    >
      <b-form-input
        id="ruName"
        v-model="$v.form.ruName.$model"
        type="text"
        :state="validateState('ruName')"
      />
      <b-form-text
        v-if="$v.form.ruName.$dirty && !$v.form.ruName.required"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_empty') }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.ruName.$dirty && !$v.form.ruName.maxLength"
        text-variant="danger"
      >
        {{ $t('herbarium_form_page_input_name_error_length') }}
        {{ $v.form.ruName.$params.maxLength.max }}
      </b-form-text>
      <b-form-text
        v-if="$v.form.ruName.$dirty && !$v.form.ruName.alphaRU"
        text-variant="danger"
      >
        {{ $t('profile_classification_modal_ru_input_valid_message') }}
      </b-form-text>
    </b-form-group>
    <div class="btn-block">
      <b-button type="submit" class="mr-3 success">
        {{ $t('profile_classification_modal_button_send') }}
      </b-button>
    </div>
  </b-form>
</template>

<script>
import { mapGetters } from 'vuex'
const alphaRU = helpers.regex('alpha', /^[а-яА-ЯёЁ,-. ]*$/)
const alphaEN = helpers.regex('alpha', /^[a-zA-Z,-. ]*$/)
import { required, maxLength, helpers } from 'vuelidate/lib/validators'

export default {
  props: {
    confirmation: { type: Function, default: () => {} },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    activeItem: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: {
        laName: '',
        enName: '',
        ruName: '',
        type: '',
        s: '',
        parentId: ''
      }
    }
  },
  validations: {
    form: {
      ruName: { required, maxLength: maxLength(50), alphaRU },
      enName: { required, maxLength: maxLength(50), alphaEN },
      laName: {
        required,
        maxLength: maxLength(50),
        alphaLA: (value, vm) =>
          vm.type === 'Family'
            ? value[0] === vm.s && /[a-zA-Z,-. ]/.test(value)
            : true
      },
      type: { maxLength: maxLength(50) }
    }
  },
  computed: {
    ...mapGetters('classification', ['symbol']),
    checkType() {
      if (this.isEdit) {
        return this.activeItem.type
      }

      if (!this.activeItem.type) {
        return 'Family'
      }

      return 'Genus'
    }
  },
  mounted() {
    if (this.isEdit) {
      this.form = { ...this.activeItem }
    } else {
      this.form.parentId = this.activeItem.id || null
    }
    this.form.type = this.checkType
    this.form.s = this.symbol
  },
  methods: {
    async submitHandler() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      delete this.form.s
      let data = {}
      if (this.isEdit) {
        data = await this.$store.dispatch('classification/editClassification', {
          edit: this.form,
          id: this.activeItem.id
        })
        this.$root.$emit('bv::hide::modal', 'editItem')
      } else {
        data = await this.$store.dispatch(
          'classification/sendClassification',
          this.form
        )
        this.$root.$emit('bv::hide::modal', 'addItem')
      }
      this.confirmation(this.activeItem, data)
    },
    validateState(name) {
      const { $dirty, $error, $model, $invalid } = this.$v.form[name]
      if (!$model.length && !$invalid) return null
      return $dirty ? !$error : null
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-block {
  display: flex;
  justify-content: flex-end;
}
</style>
