<template>
  <div>
    <b-badge
      v-for="symbol of alphabet"
      :key="symbol"
      variant="success"
      :active="symbol === selected"
      class="p-2 m-1 alphabet"
      @click="getClassificationSymbol(symbol)"
    >
      {{ symbol }}
    </b-badge>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
]

export default {
  data() {
    return {
      selected: 'A'
    }
  },
  computed: {
    alphabet() {
      return ALPHABET
    }
  },

  async mounted() {
    await this.getFamily(`classification/family?&search=${this.selected}`)
  },
  methods: {
    ...mapActions('classification', ['getFamily']),
    async getClassificationSymbol(symbol) {
      this.selected = symbol
      await this.getFamily(`classification/family?&search=${symbol}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.alphabet {
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
}
.active {
  background-color: #2c3e50;
}
</style>
